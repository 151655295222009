import { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../../controllers/authController";
import '../../styles/dashboard.css';
import ListaProdutosEdit from "../products_edit";

function DashboardEdit() {
    const [atualizarLista, setAtualizarLista] = useState(false);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productFormatoTamanho, setProductFormatoTamanho] = useState('');
    const [productCores, setProductCores] = useState('');
    const [productPapel, setProductPapel] = useState('');
    const [productEnobrecimento, setProductEnobrecimento] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]); // Armazena as categorias
    const [productTags, setProductTags] = useState('');
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [imagePreviews, setImagePreviews] = useState([]);
    const imagesToRemoveRef = useRef([]);
    const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);

    useEffect(() => {
        // Carregar categorias ao carregar a página
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${api}/categorias/get`);
                const data = await response.json();
                setCategories(data.categorias || []);
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleThumbnailSelect = (index) => {
        setSelectedThumbnailIndex(index);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const updatedPreviews = imagePreviews.filter(image => !image.includes(file.name));
        const imageUrl = URL.createObjectURL(file);

        setSelectedFiles([file]);
        setImagePreviews([...updatedPreviews, imageUrl]);
    };

    const handleImageRemove = (indexToRemove) => {
        const removedImageUrl = imagePreviews[indexToRemove];
        const updatedPreviews = imagePreviews.filter((_, index) => index !== indexToRemove);
        setImagePreviews(updatedPreviews);

        imagesToRemoveRef.current.push(removedImageUrl);
    };

    const handleProductUpdate = () => {
        setAtualizarLista(prevState => !prevState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!productName || !productDescription || !productTags) {
            alert('Por favor, preencha os campos obrigatórios: Nome, Descrição e Tags.');
            return;
        }

        const formData = new FormData();
        formData.append('nome', productName);
        formData.append('descricao', productDescription);
        formData.append('tags', productTags);
        formData.append('thumbnail_index', selectedThumbnailIndex);

        // Adiciona os campos opcionais apenas se forem preenchidos
        if (productFormatoTamanho) formData.append('formato_tamanho', productFormatoTamanho);
        if (productCores) formData.append('cores', productCores);
        if (productPapel) formData.append('papel', productPapel);
        if (productEnobrecimento) formData.append('enobrecimento_acabamento', productEnobrecimento);
        if (category) formData.append('categoria_id', category);
        formData.append('imagensRemover', JSON.stringify(imagesToRemoveRef.current));

        if (selectedFiles) {
            for (const file of selectedFiles) {
                formData.append('imagens', file);
            }
        }

        try {
            const token = getTokenFromLocalStorage();

            if (!token) {
                console.error('Token não encontrado. O usuário não está autenticado.');
                alert('Usuário não autorizado.');
                window.location.href = '/dashboard/login';
                return;
            }

            const response = await fetch(`${api}/produtos/update/${productId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                alert('Produto atualizado com sucesso!');
                handleProductUpdate();
            } else {
                alert('Não foi possível atualizar o produto. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao atualizar o produto:', error);
            alert('Erro ao tentar atualizar o produto.');
        }
    };

    const onProductSelect = (produto) => {
        setProductId(produto.id);
        setProductName(produto.nome);
        setProductDescription(produto.descricao);
        setProductFormatoTamanho(produto.formato_tamanho || '');
        setProductCores(produto.cores || '');
        setProductPapel(produto.papel || '');
        setProductEnobrecimento(produto.enobrecimento_acabamento || '');
        setCategory(produto.categoria_id);
        setProductTags(produto.tags || '');

        const imageUrls = produto.url_imagem ? produto.url_imagem.split(',').map(url => `${api}/${url}`) : [];
        setImagePreviews(imageUrls);
    };

    return (
        <div>
            <MDBContainer className="dashboard-content-container">
                <MDBRow className="dashboardRow">
                    <h2>EDIÇÃO DE PRODUTO</h2>
                    <h6>Selecione abaixo o produto que deseja editar</h6>
                </MDBRow>
                <MDBRow fluid style={{ padding: '0', width: '100%', margin: '0' }}>
                    <ListaProdutosEdit onProductSelect={onProductSelect} atualizarLista={atualizarLista} notificarAtualizacao={handleProductUpdate} />
                </MDBRow>
            </MDBContainer>
            <MDBContainer className="dashboard">
                <MDBRow fluid className="dashboardRow" style={{ padding: '0' }}>
                    <form onSubmit={handleSubmit} className="dashboardForm">
                        <MDBCol className="dashboardCol">
                            <h6>Para atualizar seu produto, altere as informações abaixo</h6>
                            <MDBRow md={12} className="box-section" style={{ width: '90%' }}>
                                <MDBCol md={12}>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Categoria</label>
                                        <select
                                            id="category"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                            className="dashboardRadius"
                                        >
                                            <option value="" disabled>Selecione uma categoria</option>
                                            {categories.map(cat => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Nome do Produto <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            id="productName"
                                            type="text"
                                            placeholder="Ex: Balanço folha, Balanço Bowl, Etc"
                                            value={productName}
                                            onChange={(e) => setProductName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Descrição do Produto <span style={{ color: 'red' }}>*</span></label>
                                        <textarea
                                            id="productDescription"
                                            rows={5}
                                            maxLength="2000"
                                            value={productDescription}
                                            onChange={(e) => setProductDescription(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Formato e Tamanho</label>
                                        <input
                                            id="formatoTamanho"
                                            type="text"
                                            placeholder="Ex: 1x1, A4, etc"
                                            value={productFormatoTamanho}
                                            onChange={(e) => setProductFormatoTamanho(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Cores</label>
                                        <input
                                            id="cores"
                                            type="text"
                                            placeholder="Ex: CMYK, Pantone, etc"
                                            value={productCores}
                                            onChange={(e) => setProductCores(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Papel</label>
                                        <input
                                            id="papel"
                                            type="text"
                                            placeholder="Ex: Couchê, Offset, etc"
                                            value={productPapel}
                                            onChange={(e) => setProductPapel(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Enobrecimento / Acabamento</label>
                                        <input
                                            id="enobrecimentoAcabamento"
                                            type="text"
                                            placeholder="Ex: Verniz, Laminação, etc"
                                            value={productEnobrecimento}
                                            onChange={(e) => setProductEnobrecimento(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Tags (Palavras-chave) <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            id="tags"
                                            type="text"
                                            placeholder="Ex: brinde, personalizado, caneca"
                                            value={productTags}
                                            onChange={(e) => setProductTags(e.target.value)}
                                            required
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md={12} className="dashboardCol image-view-holder" style={{ width: '100%' }}>
                                    <div className="image-preview">
                                        {imagePreviews.map((image, index) => (
                                            <div key={index}>
                                                <div className={`thumbnail-container ${index === selectedThumbnailIndex ? 'selected' : ''}`}>
                                                    {index === selectedThumbnailIndex && <div className="thumbnail-text">Thumbnail</div>}
                                                    <img
                                                        src={image}
                                                        alt="Preview"
                                                        style={{ width: "100px", height: "100px", margin: "10px", borderRadius: '15px' }}
                                                        onClick={() => handleThumbnailSelect(index)}
                                                    />
                                                </div>
                                                <MDBBtn className="remove-btn" onClick={() => handleImageRemove(index)}>Remover</MDBBtn>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-4">
                                        <label style={{ padding: '15px' }} htmlFor="productImages">Imagens do Produto</label>
                                        <input
                                            className="upload-btn"
                                            type="file"
                                            id="productImages"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <MDBBtn className="confirmBtn" type="submit">CONFIRMAR E ATUALIZAR PRODUTO</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </form>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardEdit;
