import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from '../src/screen/index';
import Category from '../src/screen/category';
import About from '../src/screen/about';
import Galery from './screen/gallery';
import Product from './screen/product';
import Products from './screen/products';
import Dashboard from './screen/dashboard';
import { Login } from './components/dashboard/dashboard_login.js';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/categorias/:categoria_slug?" element={<Category />} /> {/* categoria_slug é opcional */}
          <Route path="/pdv" element={<About />} />
          <Route path="/galeria" element={<Galery />} />
          <Route path="/produto/:slug" element={<Product />} />
          <Route path="/produtos" element={<Products />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/login" element={<Login />} />
        </Routes>
      </Router>
      <FloatingWhatsApp
        phoneNumber="5511XXXXX-XXXX"
        accountName="PDV em Foco"
        statusMessage="Geralmente responde rápido"
        chatMessage="Olá! Como podemos ajudar?"
      />
    </>
  );
}

export default App;
