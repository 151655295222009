import React, { useState, useRef } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";
import { IoLogoWhatsapp } from "react-icons/io5";
import "../../styles/contato.css";
import "../../styles/colors.css";
import { api } from "../../controllers/api-controller";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { SlEarphonesAlt } from "react-icons/sl";

const Contato = () => {
  const [step, setStep] = useState(1);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    mensagem: "",
    newsletter: false,
    outraInfo: "",
  });

  // Função para lidar com as mudanças nos inputs e checkbox
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Função para passar para a próxima etapa
  const handleNextStep = (e) => {
    e.preventDefault();
    if (formData.nome && formData.email && formData.mensagem) {
      setStep(2);
    } else {
      alert("Por favor, preencha todos os campos.");
    }
  };

  // Função para voltar à etapa anterior
  const handlePrevStep = (e) => {
    e.preventDefault();
    setStep(1);
  };

  // Função para enviar o formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${api}/contato`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Obrigado pelo contato! Entraremos em breve em contato.");
        setFormData({
          nome: "",
          email: "",
          mensagem: "",
          newsletter: false,
          outraInfo: "",
        });
        setStep(1);
      } else {
        alert("Houve um erro ao enviar o formulário. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      alert("Erro ao enviar o formulário.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <MDBContainer fluid className="contato-section bg-blue" id="orcamento">
      <MDBRow className="justify-content-evenly align-items-center">
        <MDBCol md="4" className="text-left contato-onboarding-form">
          <h2>Vamos dar vida a suas ideias?</h2>
          <p>
          Estamos prontos para ajudar a transformar suas ideias em realidade! 
          Preencha o formulário ou fale diretamente com nossa equipe atráves das opções abaixo. 
          Estamos prontos para oferecer as melhores soluções para o seu negócio. 
          </p>
          <div className="contato-links">
            <a href='#!'  className="whatsapp"><IoLogoWhatsapp size={22} /> (11) XXXXX-XXXX</a>
            <a href='#!'  className="telefone"><SlEarphonesAlt size={21} /> (11) XXXX-XXXX</a>
          </div>
          <div className="contato-links">
            <a href='#!'  className="mail"><MdOutlineAlternateEmail size={22} /> contato@pdvemfoco.com.br</a>
          </div>
        </MDBCol>
        <MDBCol md="5">
          <div>
            {/* Primeira Etapa do Formulário */}
            {step === 1 && (
              <form
                className="form-contato"
                ref={formRef}
                onSubmit={handleNextStep}
              >
                <label>Seu nome</label>
                <input
                  name="nome"
                  placeholder="Digite seu nome"
                  value={formData.nome}
                  onChange={handleInputChange}
                  className="mb-4"
                  required
                />
                <label>Seu melhor e-mail</label>
                <input
                  name="email"
                  placeholder="Digite seu e-mail"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mb-4"
                  type="email"
                  required
                />
                <label>Sua mensagem</label>
                <textarea
                  name="mensagem"
                  placeholder="Digite sua mensagem"
                  value={formData.mensagem}
                  onChange={handleInputChange}
                  className="mb-4"
                  required
                />
                <MDBBtn className="contato-btn" type="submit">
                  Próxima Etapa
                </MDBBtn>
              </form>
            )}

            {/* Segunda Etapa do Formulário */}
            {step === 2 && (
              <form
                className="form-contato"
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <label>Informações Adicionais (Opcional)</label>
                <textarea
                  name="outraInfo"
                  value={formData.outraInfo}
                  onChange={handleInputChange}
                  className="mb-4"
                  rows={3}
                />
                <p className="form-check-label">
                  Ao clicar em enviar, você concorda com nossa{" "}
                  <a href="#!" target="_blank">
                    Política de Privacidade
                  </a>
                  .
                </p>
                <MDBBtn
                  color="secondary"
                  onClick={handlePrevStep}
                  className="ml-2 contato-btn-back"
                >
                  Voltar
                </MDBBtn>
                <MDBBtn
                  className="contato-btn"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Enviando..." : "Enviar"}
                </MDBBtn>
              </form>
            )}

            {/* Barra de Progresso */}
            <MDBProgress className="my-4">
              <MDBProgressBar
                width={(step / 2) * 100}
                valuemin={0}
                valuemax={100}
              />
            </MDBProgress>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Contato;
