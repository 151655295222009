import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';  
import '../styles/footer.css'; 
import "../styles/colors.css";
import Logo from '../img/Logo-PDV.svg'

const Footer = () => {
    return (
        <footer className="footer bg-blue">
            <MDBContainer fluid>
                <MDBRow className="align-items-center footer-body">
                    <MDBCol md="2" className="footer-logo">
                        <img src={Logo} alt="Logo" className="img-fluid" />
                    </MDBCol>
                    <MDBCol md="6" className="footer-links">
                        <h5>Acesso rápido</h5>
                        <ul>
                            <li><a href="/">Inicio</a></li>
                            <li><a href="/categorias">Produtos</a></li>
                            <li><a href="/galeria">Galeria</a></li>
                            <li><a href="/pdv">Sobre Nós</a></li>
                        </ul>
                    </MDBCol>
                    <MDBCol md="4" className="footer-social">
                        <div className="social-icons">
                            <a href="https://www.instagram.com/pdvemfoco/" target='_blank' rel="noreferer"><FaInstagram /></a>
                            <a href="https://www.facebook.com/pdvemfoco/" target='_blank' rel="noreferer"><FaLinkedin /></a>
                            <a href="https://www.facebook.com/pdvemfoco/" target='_blank' rel="noreferer"><FaFacebook /></a>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="footer-bottom">
                    <MDBCol md="6" className="text-left">
                        <p>©2024 PDV em foco. Todos direitos reservados</p>
                    </MDBCol>
                    <MDBCol md="6" className="text-right">
                        <a href="#privacidade">Política de Privacidade</a>
                        <a href="#termos" className="ml-3">Termos</a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </footer>
    );
};

export default Footer;
